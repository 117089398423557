<template>
  <div class="commonTeacherList">
    <div class="teacherTeamList flex spacearound flexWrap">
      <div
        class="
          teacherTeamLists
          flex
          alignCenter
          flexColumn
          teacherHover
          cursorP
        "
        v-for="(jm, jx) in theacherList"
        :key="jx"
        @click="goother({ path: '/teacherdetail', query: { id: jm.id } })"
      >
        <img :src="jm.portrait" alt="" />
        <div class="teacherTeamListsText c33 fontW" style="margin-top: 8px">
          {{ jm.real_name }}
        </div>
        <div
          class="teacherTeamListsPost moreHiddenthree"
          style="margin-top: 5px; width: 85%"
        >
          {{ jm.duties }}
        </div>
        <!-- <div class="teacherTeamListsPost moreHidden">{{jm.unit}}</div>
               <div class="teacherTeamListsPost moreHidden">{{jm.professional}}</div> -->
      </div>
      <div
        class="teacherTeamLists"
        style="opacity: 0"
        v-for="(km, kx) in showNumber()"
        :key="kx"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'commonTeacherList',
  props: {
    theacherList: {
      type: Array,
      default: []
    }
  },
  data() {
    return {}
  },
  created() {},
  methods: {
    showNumber() {
      const number = this.theacherList.length % 5
      if (number > 0) {
        return 5 - number
      }
      return 0
    }
  }
}
</script>
<style lang="scss" scoped>
.teacherHover:hover {
  margin-top: 6px !important;
}
.commonTeacherList {
  width: 100%;
  .teacherTeamLists {
    margin-top: 14px;
    width: 229px;
    height: 300px;
    border-radius: 8px;
    background: #fff;
    transition: all 0.3s;
    .teacherTeamListsText {
      line-height: 36px;
    }
    .teacherTeamListsPost {
      font-size: 15px;
      color: #898989;
      line-height: 24px;
    }
    img {
      margin-top: 40px;
      width: 112px;
      height: 112px;
      border-radius: 50%;
      // height: 156px;
    }
  }
}
</style>