var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"courseList",class:_vm.showType == 2 ? 'bgf' : ''},[(_vm.showType == 1)?_c('div',{staticClass:"flex flexWrap",class:_vm.showType == 1 ? 'spacebetween' : 'spacearound',staticStyle:{"width":"100%"}},[_vm._l((_vm.courseList),function(im,ix){return _c('div',{key:ix,staticClass:"rowBox cursorP",class:_vm.min ? 'rowBoxMin' : _vm.bigCourse ? 'HomeBigCourse' : '',on:{"click":function($event){return _vm.goother({
          path: '/play',
          query: { id: _vm.studyRe ? im.course_id : im.id }
        })}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(im.type == 1),expression:"im.type == 1"}],staticClass:"zhiBG",attrs:{"src":require("@/assets/img/common/zhiboBG.png"),"alt":""}}),_c('img',{staticClass:"minimg",attrs:{"src":im.face_img ? im.face_img : im.cover,"alt":""}}),_c('div',{staticClass:"rowBoxContent c33 fontW moreHidden marginAuto",staticStyle:{"height":"53px"}},[_vm._v(" "+_vm._s(im.title ? im.title : im.c_title)+" ")]),_c('div',{staticClass:"rowBoxTime marginAuto",staticStyle:{"width":"90%"}},[_vm._v(" "+_vm._s(im.duration)+" ")]),_c('div',{staticClass:"rowBoxTeacher marginAuto flex alignCenter spacebetween"},_vm._l((im.lecturer_info),function(jm,jx){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(jx < 2),expression:"jx < 2"}],key:jx,staticClass:"rowBoxTeacherList flex alignCenter fwtHidden",staticStyle:{"text-align":"left","width":"85px"}},[_c('img',{attrs:{"src":jm.portrait,"alt":""}}),_vm._v(" "+_vm._s(jm.real_name)+" ")])}),0),_c('div',{staticClass:"studycatch",staticStyle:{"text-align":"left","margin-left":"20px","margin-top":"10px"}},[_vm._v(" "+_vm._s(!im.last_time ? '未开始' : `观看至${im.last_time}`)+" ")])])}),_vm._l((3),function(val){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.min),expression:"min"}],key:val + 'c',staticStyle:{"width":"218px"}})}),_vm._l((3),function(val){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.min),expression:"!min"}],key:val + 'v',staticStyle:{"width":"268px"}})})],2):_c('div',{staticClass:"flex flexWrap",class:_vm.showType == 1 ? 'spacebetween' : 'spacearound',staticStyle:{"width":"100%","padding-bottom":"30px"}},[_vm._l((_vm.courseList),function(im,ix){return _c('div',{key:ix + 'n',staticClass:"colBox flex spacebetween cursorP",on:{"click":function($event){return _vm.goother({
          path: '/play',
          query: { id: _vm.studyRe ? im.course_id : im.id }
        })}}},[_c('img',{attrs:{"src":im.face_img ? im.face_img : im.cover,"alt":""}}),_c('div',{staticClass:"colBoxRight flex flexColumn spacebetween"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(im.type == 1),expression:"im.type == 1"}],staticClass:"zhiBG",attrs:{"src":require("@/assets/img/common/zhiboBG.png"),"alt":""}}),_c('div',{staticClass:"colBoxRightName c33 moreHidden"},[_vm._v(" "+_vm._s(im.title ? im.title : im.c_title)+" ")]),_c('div',{staticClass:"colBoxRightTime"},[_vm._v(" "+_vm._s(im.duration)+" ")]),_c('div',{staticClass:"colBoxRightTeacher flex"},_vm._l((im.lecturer_info),function(jm,jx){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(jx < 2),expression:"jx < 2"}],key:jx + 'b',staticClass:"colBoxRightTeachers flex alignCenter",staticStyle:{"margin-right":"15px"}},[_c('img',{staticStyle:{"margin-right":"6px"},attrs:{"src":jm.portrait,"alt":""}}),_c('span',[_vm._v(_vm._s(jm.real_name))])])}),0)])])}),_vm._l((3),function(val){return _c('div',{key:val + 'm',staticStyle:{"width":"560px"}})})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }