<template>
  <div class="courseList" :class="showType == 2 ? 'bgf' : ''">
    <div
      v-if="showType == 1"
      style="width: 100%"
      class="flex flexWrap"
      :class="showType == 1 ? 'spacebetween' : 'spacearound'"
    >
      <!-- 新填讲师 -->
      <!-- <teacher-list v-if="iSteacher" :theacherList="courseList[0].lecturer_info"></teacher-list> -->
      <div
        class="rowBox cursorP"
        :class="min ? 'rowBoxMin' : bigCourse ? 'HomeBigCourse' : ''"
        v-for="(im, ix) in courseList"
        :key="ix"
        @click="
          goother({
            path: '/play',
            query: { id: studyRe ? im.course_id : im.id }
          })
        "
      >
        <img
          v-show="im.type == 1"
          class="zhiBG"
          src="@/assets/img/common/zhiboBG.png"
          alt=""
        />
        <img
          :src="im.face_img ? im.face_img : im.cover"
          alt=""
          class="minimg"
        />
        <div
          class="rowBoxContent c33 fontW moreHidden marginAuto"
          style="height: 53px"
        >
          {{ im.title ? im.title : im.c_title }}
        </div>
        <div class="rowBoxTime marginAuto" style="width: 90%">
          {{ im.duration }}
          <!-- {{im.type == 1 ? '直播时间' : '完成时间'}}： -->
        </div>
        <div class="rowBoxTeacher marginAuto flex alignCenter spacebetween">
          <div
            class="rowBoxTeacherList flex alignCenter fwtHidden"
            style="text-align: left; width: 85px"
            v-for="(jm, jx) in im.lecturer_info"
            :key="jx"
            v-show="jx < 2"
          >
            <img :src="jm.portrait" alt="" />
            {{ jm.real_name }}
          </div>
        </div>
        <div
          class="studycatch"
          style="text-align: left; margin-left: 20px; margin-top: 10px"
        >
          {{ !im.last_time ? '未开始' : `观看至${im.last_time}` }}
        </div>
      </div>
      <div
        v-show="min"
        style="width: 218px"
        v-for="val in 3"
        :key="val + 'c'"
      ></div>
      <div
        v-show="!min"
        style="width: 268px"
        v-for="val in 3"
        :key="val + 'v'"
      ></div>
      <!-- <div class="rowBox" :class="min ? 'rowBoxMin' : ''" style="opacity:0" v-for="ix in showNumbers()" :key="ix"></div> -->
    </div>
    <div
      v-else
      style="width: 100%; padding-bottom: 30px"
      class="flex flexWrap"
      :class="showType == 1 ? 'spacebetween' : 'spacearound'"
    >
      <div
        class="colBox flex spacebetween cursorP"
        v-for="(im, ix) in courseList"
        :key="ix + 'n'"
        @click="
          goother({
            path: '/play',
            query: { id: studyRe ? im.course_id : im.id }
          })
        "
      >
        <img :src="im.face_img ? im.face_img : im.cover" alt="" />
        <div class="colBoxRight flex flexColumn spacebetween">
          <img
            v-show="im.type == 1"
            class="zhiBG"
            src="@/assets/img/common/zhiboBG.png"
            alt=""
          />
          <div class="colBoxRightName c33 moreHidden">
            {{ im.title ? im.title : im.c_title }}
          </div>
          <div class="colBoxRightTime">
            {{ im.duration }}
            <!-- {{im.type == 1 ? '直播时间' : '完成时间'}}： -->
          </div>
          <div class="colBoxRightTeacher flex">
            <div
              class="colBoxRightTeachers flex alignCenter"
              style="margin-right: 15px"
              v-for="(jm, jx) in im.lecturer_info"
              :key="jx + 'b'"
              v-show="jx < 2"
            >
              <img :src="jm.portrait" alt="" style="margin-right: 6px" />
              <span>{{ jm.real_name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 560px" v-for="val in 3" :key="val + 'm'"></div>
      <!-- <div class="colBox" style="opacity:0" v-for="ix in showNumbers()" :key="ix"></div> -->
    </div>
  </div>
</template>
<script>
import { istoinfo } from '@/utils/Api/commonList'
import teacherList from '@/components/teacherListmore'
export default {
  components: { teacherList },
  props: {
    courseList: {
      type: Array,
      default: () => []
    },
    /* 1.横排 -一排多个    2.竖排  */
    showType: {
      type: Number,
      default: 1
    },
    /* 显示个数 */
    showNumber: {
      type: Number,
      default: 4
    },
    min: {
      type: Boolean,
      default: false
    },
    studyRe: {
      type: Boolean,
      default: false
    },
    bigCourse: {
      type: Boolean,
      default: false
    },
    iSteacher:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      istoinfoContent: null
    }
  },
  created(){
    console.log(this.courseList[0].lecturer_info,'=================');
  },
  methods: {
    showNumbers() {
      const number =
        parseInt(this.courseList.length) % parseInt(this.showNumber)
      if (number > 0) {
        return this.showNumber - number
      }
      return 0
    },
    detailsClick(e) {
      // goother({path:'/play',query:{id: im.id}})
      this.istoinfoPro(e)
    },
    async istoinfoPro(e) {
      this.istoinfoContent = await istoinfo({ course_id: e })
      console.log('istoinfoContent', this.istoinfoContent)
      // goother({path:'/play',query:{id: im.id}})
      // if(){}else{}
    }
  },
  watch: {
    courseList() {}
  }
}
</script>
<style lang="scss" scoped>
.HomeBigCourse {
  width: 290px;
}
.rowBoxMin {
  width: 216px !important;
  min-height: 300px !important;
  border: 1px solid #cecece;
  .minimg {
    width: 100%;
    height: 121.5px;
  }
  .rowBoxContent {
    font-size: 15px !important;
    height: 44px !important;
    line-height: 20px;
    width: 188px !important;
  }
  .rowBoxTeacher {
    width: 188px !important;
    .rowBoxTeacherList {
      width: 49%;
      margin-top: 0px;
      img {
        margin-right: 5px !important;
      }
    }
  }
}
.courseList {
  width: 100%;
}
.bgf {
  background: #ffffff;
}
.rowBox {
  margin-top: 23px;
  transition: all 0.3s;
  &:hover {
    margin-top: 10px;
    margin-bottom: 13px;
  }
}
</style>