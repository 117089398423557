<template>
  <!-- 2期 添加出席环节 wwl -->
  <div class="thacerDetail">
    <nav-view :titleName="titleName"></nav-view>
    <div class="teacherinfo centerWidth marginAuto flex spacebetween">
      <div class="teacherImg flex spacearound alignCenter">
        <img :src="lecturerInfo.portrait"
             alt="">
      </div>
      <div class="teacherReamkes">
        <div class="rightTitle c33 fontW">讲师简介</div>
        <div class="teachername">
          <li>{{lecturerInfo.real_name}}</li>
          <li>{{lecturerInfo.unit}}</li>
          <li>{{lecturerInfo.professional}}</li>
        </div>
        <div class="teacherediter"
             v-html="lecturerInfo.explain"></div>
      </div>
    </div>
    <div class="centerWidth marginAuto"
         style="padding-bottom:20px;">
      <div class="tab_box">
        <span v-for='(row,index) in tabList'
              :class="activeTab == index ? 'active' :''"
              :key='index'
              @click="activeTab = index">{{row}}</span>
      </div>
      <!-- <div class="rightTitle c33 fontW"
           style="height:64px;line-height:64px;">主讲课程</div> -->
      <div v-if='activeTab == 0'>
        <course-list v-if="lecturerInfo.course_info && lecturerInfo.course_info.length > 0"
                     :courseList="lecturerInfo.course_info"
                     :showType="1"
                     :showNumber="4"></course-list>
        <temp-data v-else
                   :shouwtext="'暂无主讲课程'"></temp-data>
        <div></div>
      </div>
      <div v-else>
        <div v-if='tutor_attend && tutor_attend.length > 0'>
          <div class="row_box"
               v-for='(item,index) in tutor_attend'
               :key='index'>
            <div class="name">{{item.meet_name}}</div>
            <div class="row"
                 v-for='(ctx,ic) in item.forum'
                 :key='ic'>
              <div class="title">{{ctx.name}}</div>
              <div class="content"
                   v-for='(ctxc,ic) in ctx.lecturer'
                   :key='ic'>
                <div class="left">
                  {{ctxc.start_time}} - {{ctxc.end_time}}
                </div>
                <div class="right">
                  {{ctxc.title}}
                </div>
              </div>
            </div>
          </div>
        </div>
        <temp-data v-else
                   :shouwtext="'暂无主讲课程'"></temp-data>
      </div>
    </div>
  </div>
</template>
<script>
import { lecturer_info } from '@/utils/Api/commonList'
import { get_tutor_attend } from '@/utils/Api/meeting'
import navView from '@/components/navView.vue'
import courseList from '@/components/courseList'
import tempData from '@/components/tempData'

export default {
  name: 'thacerDetail',
  components: { navView, courseList, tempData },
  data () {
    return {
      titleName: '讲师详情',
      lecturerInfo: {},
      activeTab: 0,
      tabList: ['主讲课程', '出席环节'],
      tutor_attend: []
    }
  },
  created () {
    if (this.$route.query.activeTab) this.activeTab = this.$route.query.activeTab
    this.initial(this.$route.query.id)
    this.get_attend(this.$route.query.id)
  },
  methods: {
    async initial (value) {
      let res = await lecturer_info({ lecturer_id: value })
      if (res) { this.lecturerInfo = res }
    },
    async get_attend (id) {
      let res = await get_tutor_attend({ lecturer_id: id })
      if (res) this.tutor_attend = res
    }


  }
}
</script>
<style lang="scss" scoped>
.thacerDetail {
  background: #f7f8fa;
  .teacherinfo {
    margin-top: 36px;
    background: #ffffff;
  }
  .teacherReamkes {
    width: 1016px;
    // height: 220px;
    padding: 20px 0 40px;
    background: #ffffff;
    .teacherediter {
      width: 929px;
      // margin-top: 22px;
      // margin-left: 38px;
      font-size: 15px;
      color: #707070;
      text-align: left;
      margin-top: 22px;
    }
    .teachername {
      // margin-left: 38px;
      margin-top: 10px;
      height: 22px;
      li {
        float: left;
        padding-right: 13px;
        margin-left: 13px;
        font-size: 16px;
        line-height: 16px;
        color: #898989;
        border-right: 1px solid #898989;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          border: none;
        }
      }
    }
  }
  .teacherImg {
    width: 163px;
    height: 220px;
    // border: 1px dashed #969798;
    img {
      width: 133px;
      height: 133px;
      border-radius: 50%;
      // height: 185px;
      // border: 1px dashed #969798;
    }
  }
}
.rightTitle {
  height: 28px;
  line-height: 28px;
  text-align: left;
  padding-left: 14px;
  position: relative;
  font-size: 20px;
  margin-top: 20px;
  &::after {
    content: ' ';
    display: inline-block;
    position: absolute;
    width: 4px;
    height: 20px;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #47d7e3;
  }
}
.tab_box {
  margin-top: 17px;
  font-size: 20px;
  color: #575757;
  font-weight: 700;
  margin-bottom: 32px;
  text-align: left;
  span {
    cursor: pointer;
    display: inline-block;
    margin-right: 40px;
  }
  .active {
    position: relative;
    &::before {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -9px;
      content: '';
      width: 27px;
      height: 4px;
      background: #47d7e3;
    }
  }
}
.row_box {
  padding: 30px 30px 60px;
  margin-bottom: 32px;
  background-color: #fff;
  .name {
    font-size: 15px;
    box-sizing: border-box;
    text-align: left;
    line-height: 22px;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 700;
    color: #fff;
    width: 100%;
    padding: 10px 20px 10px 80px;
    background-color: #47d7e3;
    border-radius: 5px 5px 0 0;
  }
  .title {
    text-align: left;
    font-size: 15px;
    line-height: 22px;
    color: #333;
    line-height: 21px;
    padding: 22px 60px 20px 80px;
  }
  .row {
    .content {
      box-sizing: border-box;
      padding: 20px 20px 30px;
      width: 100%;
      display: flex;
      font-size: 24rpx;
      line-height: 32rpx;
      background: #fafafa;
      font-size: 16px;
      line-height: 22px;
      &:nth-child(odd) {
        background: #ebebeb;
      }
    }
    .content .left {
      text-align: center;
      box-sizing: border-box;
      width: 20%;
    }
    .content .right {
      text-align: left;
      padding-left: 10px;
      width: 80%;
      box-sizing: border-box;
      color: #333333;
    }
  }
}
</style>